<template>
  <v-sheet
    class="pt-3"
    :style="columnStyle"
    color="blue-grey lighten-2"
    rounded
  >
    <div class="column d-flex justify-space-between align-center px-4 crm-column-header">
      <h4 class="column-title">{{ title }}</h4>
      <slot name="action"></slot>
    </div>

    <div class="d-flex justify-space-between mb-3 px-4">
      <h6>${{ totalAmount }}</h6>
      <h6>{{ applicationsCount }}</h6>
    </div>

    <simplebar class="scroll-column drag-wrapper" data-simplebar-auto-hide="false">
      <Draggable
        class="drag-inner"
        v-bind="dragOptions"
        v-model="storedApplicationsDraggable"
        :dataId="columnId"
        :move="checkMove"
      >
        <div
          :class="{ 'no-pointer': currentUser !== application?.data?.adviser }"
          v-for="application in applications"
          :key="application.id"
        >
          <CRMApplicationCard
            :class="{ transparent: currentUser !== application?.data?.adviser }"
            class="mx-3"
            :application="application"
            :columnId="columnId"
          />
        </div>
      </Draggable>
    </simplebar>
  </v-sheet>
</template>

<script>
import simplebar from 'simplebar-vue';
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import CRMApplicationCard from '@/components/CRMApplicationCard.vue';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';

export default {
  name: 'BaseCRMColumn',
  components: {
    CRMApplicationCard,
    simplebar,
    Draggable,
  },

  props: {
    currentUser: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    columnWidth: {
      type: Number,
      required: false,
      default: 100,
    },

    applications: {
      required: true,
      type: Array,
    },

    columnId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      dragEnter: false,
      newColumnId: null,
      applicationId: null,
    };
  },

  computed: {
    ...mapGetters(['SUB_APPLICATIONS']),

    dragOptions() {
      return {
        animation: 0,
        group: 'card-list',
        disabled: false,
        ghostClass: 'ghost',
      };
    },

    storedApplicationsDraggable: {
      get() {
        return this.applications;
      },

      set() {
        const isPayloadReady = Boolean(this.applicationId && this.newColumnId);

        if (isPayloadReady) {
          const payload = {
            applicationId: this.applicationId,
            oldStatus: this.columnId,
            newStatus: this.newColumnId,
          };

          this.$store.dispatch('changeApplicationStatus', payload);
        }
      },
    },

    applicationsCount() {
      return this.applications?.length ?? 0;
    },

    totalAmount() {
      const amounts = [0];

      const getApplicationsAmount = (application) => {
        const priceData = application?.data?.data?.summary?.lending_required || '';

        const formattedPrice = priceData.replace(/\s|,/g, '');

        const applicationAmount = (formattedPrice.length === 0) ? 0 : formattedPrice;

        amounts.push(parseInt(applicationAmount, 10));
      };

      this.applications.forEach(getApplicationsAmount);
      const totalAmount = amounts.reduce((a, b) => a + b);

      const isTotalAmountNotANumber = Boolean(Number.isNaN(totalAmount));
      if (isTotalAmountNotANumber) {
        return 0;
      }

      const formattedTotal = this.$options.filters.formatMillions(parseInt(totalAmount, 10));

      return formattedTotal;
    },

    columnStyle() {
      if (this.dragEnter) {
        return 'box-shadow: 0px 5px 10px 2px #fff !important;';
      }

      return '';
    },
  },

  methods: {
    checkMove({ draggedContext, relatedContext }) {
      this.applicationId = draggedContext.element.id;
      this.newColumnId = relatedContext.component.$attrs.dataId;
    },
  },
};
</script>

<style lang="scss">
.column-title {
  white-space: initial;
  @media (max-width: 1199px) {
    font-size: 15px;
  }
}

.scroll-column {
  height: 750px;
}

.column {
  min-height: 40px;
}

.drag-wrapper {
  .simplebar-content, .drag-inner {
    height: calc(100% - 22px);
  }
}

.crm-column-header {
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.transparent {
  opacity: 0.6;
}
</style>
