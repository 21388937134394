<template>
  <div
  >
    <v-hover v-slot="{ hover }">
      <v-card
        class="my-3"
        :elevation="hover ? 15:4"
        min-height="100"
        @click="openApplication"
      >
        <div
          class="card text-caption pa-3 d-flex flex-column justify-space-between"
        >
          <div
            v-if="isLastApplication"
            class="new-badge"
          >
            NEW
          </div>

          <div class="d-flex justify-space-between">
            <span class="font-weight-medium">{{ propertyAddress }}</span>
            <span class="text-subtitle-2">
              <strong>${{ total }}</strong>
            </span>
          </div>
          <div class="d-flex flex-wrap">
            <span
              v-for="(supApplicationTitle, idx) in supApplicationsTitle"
              :key="idx"
            >
              <span
                v-if="supApplicationTitle.trim().length > 0"
                class="subapp"
              >
                {{ supApplicationTitle }}
              </span>
            </span>
          </div>

          <div class="d-flex justify-space-between font-weight-light crm-card-bottom">
            {{ applicationCreatedAt }}

            <v-icon
              :color="editIcon.color"
            >
              {{ editIcon.icon }}
            </v-icon>
          </div>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>
<script>
/* eslint-disable no-param-reassign */
import firebase from 'firebase/app';
import 'firebase/firestore';

import { mapGetters } from 'vuex';
import dateFormat from 'dateformat';
import { USER_ROLES } from '@/models/helpers/consts';

import { getSubApplications } from '@/services/firebase';

import SummaryMixins from '@/mixins/SummaryMixin';

export default {
  name: 'CRMApplicationCard',

  props: {
    application: {
      type: Object,
      required: true,
    },

    columnId: {
      type: String,
      required: true,
    },
  },

  mixins: [SummaryMixins],

  data() {
    return {
      subApplications: [],
    };
  },

  mounted() {
    this.getSubApplications();
  },

  computed: {
    ...mapGetters(['APPLICATION_LAST_ID', 'APPLICATION']),

    isLastApplication() {
      const isLastApplication = Boolean(this.APPLICATION_LAST_ID === this.application?.id);

      return isLastApplication;
    },

    editIcon() {
      const isView = Boolean(this.status === 'view');

      const iconData = {
        icon: isView ? 'mdi-eye' : 'mdi-account-edit',
        color: isView ? 'info' : 'error',
      };

      return iconData;
    },

    total() {
      const total = this.$options.filters.formatMillions(parseInt(this.amount, 10));
      return total;
    },

    amount() {
      const priceData = this.application?.data?.data?.summary?.lending_required;

      const priceIsExist = Boolean(priceData !== undefined);

      if (priceIsExist) {
        const formattedPrice = priceData.replace(/\s|,/g, '');

        const price = parseFloat(formattedPrice);

        const isPriceNotValid = Number.isNaN(price);
        if (isPriceNotValid) {
          return 0;
        }

        return price;
      }

      return 0;
    },

    supApplicationsTitle() {
      const getSubApplicationTitle = (application) => {
        const {
          last_name: lastName,
          first_name: firstName,
          app_title: appTitle,
        } = application?.data?.personal_details;

        const title = `${appTitle} ${firstName?.slice(0, 1)} ${lastName}`;

        return title;
      };

      const subApplications = this.subApplications.map(getSubApplicationTitle);

      return subApplications;
    },

    applicationCreatedAt() {
      const isCreatedSecondsExists = Boolean(this.application.data.created?.seconds);
      const isCreatedNanosecondsExists = Boolean(this.application.data.created?.nanoseconds);

      const isCreatedTimeExist = Boolean(isCreatedSecondsExists && isCreatedNanosecondsExists);

      if (isCreatedTimeExist) {
        const createdAt = new firebase.firestore.Timestamp(
          this.application.data.created.seconds,
          this.application.data.created.nanoseconds,
        );

        return dateFormat(createdAt.toDate(), 'dd/mm/yyyy');
      }

      return this.application.data.created;
    },

    status() {
      const clientEdit = Boolean(this.application?.data?.data?.common?.edit === USER_ROLES.client);
      if (clientEdit) {
        return 'edit';
      }

      return 'view';
    },

    propertyAddress() {
      const addressMaxLength = 25;

      const address = this.application?.data?.data?.funding_details?.property_address;

      const isAddressGthMaxLength = Boolean(address?.length > addressMaxLength);
      if (isAddressGthMaxLength) {
        return `${address.slice(0, addressMaxLength)}...`;
      }

      return address;
    },
  },

  methods: {
    async getSubApplications() {
      const applicationId = this.application.id;

      this.subApplications = await getSubApplications(applicationId);
    },

    openApplication() {
      this.$store.commit('changeComplainStatus', false);

      this.$router.push({
        name: 'ApplicationAdviser',
        params: {
          id: this.application.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.subapp {
  display: block;
  border: 1px solid gray;
  padding: 2px;
  margin: 0 2px 4px;
  border-radius: 5px
}
.card {
  width: 100%;
  min-height: inherit;
  @media (max-width: 991px) {
    padding: 3px !important;
  }
}

.new-badge {
  position: absolute;
  bottom: 3px;
  right: 3px;
  padding: 5px;
  border-radius: 5px;
  background-color: #FB8C00;
  color: white;
  font-weight: bold;
}

.crm-card-bottom {
  flex-wrap: wrap;
  align-items: center;
}
</style>
