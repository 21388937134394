var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "pt-3",
    style: _vm.columnStyle,
    attrs: {
      "color": "blue-grey lighten-2",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "column d-flex justify-space-between align-center px-4 crm-column-header"
  }, [_c('h4', {
    staticClass: "column-title"
  }, [_vm._v(_vm._s(_vm.title))]), _vm._t("action")], 2), _c('div', {
    staticClass: "d-flex justify-space-between mb-3 px-4"
  }, [_c('h6', [_vm._v("$" + _vm._s(_vm.totalAmount))]), _c('h6', [_vm._v(_vm._s(_vm.applicationsCount))])]), _c('simplebar', {
    staticClass: "scroll-column drag-wrapper",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('Draggable', _vm._b({
    staticClass: "drag-inner",
    attrs: {
      "dataId": _vm.columnId,
      "move": _vm.checkMove
    },
    model: {
      value: _vm.storedApplicationsDraggable,
      callback: function callback($$v) {
        _vm.storedApplicationsDraggable = $$v;
      },
      expression: "storedApplicationsDraggable"
    }
  }, 'Draggable', _vm.dragOptions, false), _vm._l(_vm.applications, function (application) {
    var _application$data, _application$data2;

    return _c('div', {
      key: application.id,
      class: {
        'no-pointer': _vm.currentUser !== (application === null || application === void 0 ? void 0 : (_application$data = application.data) === null || _application$data === void 0 ? void 0 : _application$data.adviser)
      }
    }, [_c('CRMApplicationCard', {
      staticClass: "mx-3",
      class: {
        transparent: _vm.currentUser !== (application === null || application === void 0 ? void 0 : (_application$data2 = application.data) === null || _application$data2 === void 0 ? void 0 : _application$data2.adviser)
      },
      attrs: {
        "application": application,
        "columnId": _vm.columnId
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }