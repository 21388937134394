var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "my-3",
          attrs: {
            "elevation": hover ? 15 : 4,
            "min-height": "100"
          },
          on: {
            "click": _vm.openApplication
          }
        }, [_c('div', {
          staticClass: "card text-caption pa-3 d-flex flex-column justify-space-between"
        }, [_vm.isLastApplication ? _c('div', {
          staticClass: "new-badge"
        }, [_vm._v(" NEW ")]) : _vm._e(), _c('div', {
          staticClass: "d-flex justify-space-between"
        }, [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm.propertyAddress))]), _c('span', {
          staticClass: "text-subtitle-2"
        }, [_c('strong', [_vm._v("$" + _vm._s(_vm.total))])])]), _c('div', {
          staticClass: "d-flex flex-wrap"
        }, _vm._l(_vm.supApplicationsTitle, function (supApplicationTitle, idx) {
          return _c('span', {
            key: idx
          }, [supApplicationTitle.trim().length > 0 ? _c('span', {
            staticClass: "subapp"
          }, [_vm._v(" " + _vm._s(supApplicationTitle) + " ")]) : _vm._e()]);
        }), 0), _c('div', {
          staticClass: "d-flex justify-space-between font-weight-light crm-card-bottom"
        }, [_vm._v(" " + _vm._s(_vm.applicationCreatedAt) + " "), _c('v-icon', {
          attrs: {
            "color": _vm.editIcon.color
          }
        }, [_vm._v(" " + _vm._s(_vm.editIcon.icon) + " ")])], 1)])])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }